<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">通话记录</div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      :rules="rules"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="时间:" prop="dateRange">
        <el-date-picker
          v-model="searchForm.dateRange"
          placeholder="时间"
          size="small"
          type="daterange"
          :clearable="false"
          value-format="yyyy-MM-dd"
          @change="handleDateChange"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="主叫号码">
        <el-input
            v-model="searchForm.caller"
            placeholder="主叫号码"
            size="small"
            clearable
            @clear="searchForm.caller = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="被叫号码">
        <el-input
          v-model="searchForm.callee"
          placeholder="被叫号码"
          size="small"
          clearable
          @clear="searchForm.callee = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="员工">
        <el-select
          v-model="searchForm.userIdList"
          placeholder="员工"
          size="small"
          clearable
          multiple
          @clear="searchForm.userIdList = []"
        >
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :value="item.userId"
            :label="item.userName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通话状态" prop="state">
        <el-select
          v-model="searchForm.callState"
          clearable
          @clear="searchForm.state = null"
          placeholder="通话状态"
          size="small"
        >
          <el-option label="成功" :value="1"></el-option>
          <el-option label="失败" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通话时长" prop="region">
        <div>
          <el-select
            v-model="searchForm.time"
            placeholder="通话时长"
            size="small"
            @change="handleTimeChange"
            clearable
          >
            <el-option
              v-for="item in callTalkTimeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
            <el-option value="0" label="自定义时间段"></el-option>
          </el-select>
        </div>
        <div v-if="showCustomTime" style="float: left">
          <el-input
            type="text"
            size="small"
            v-model="searchForm.talkTimeStart"
            style="width: 120px"
          >
            <div slot="append">秒</div>
          </el-input>
          -
          <el-input
            type="text"
            size="small"
            v-model="searchForm.talkTimeEnd"
            style="width: 120px"
          >
            <div slot="append">秒</div>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="客户姓名">
        <el-input
          v-model="searchForm.customerName"
          placeholder="客户姓名"
          size="small"
          clearable
          @clear="searchForm.customerName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          v-model="searchForm.companyName"
          placeholder="公司名称"
          size="small"
          clearable
          @clear="searchForm.companyName = null"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索
        </el-button>
        <el-button
            type="primary"
            @click="handleExportRecordList"
            icon="search"
            size="small"
        >导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="seatAccountName"
        label="坐席名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="员工姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="caller"
        label="主叫号码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callee"
        label="被叫号码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="拨打时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callTime"
        label="通话时长"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callState"
        label="通话状态"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerName"
        label="客户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司名称"
        align="center"
      ></el-table-column>
      <el-table-column align="center" width="300" label="通话录音">
        <template slot-scope="scope">
          <audio
            style="width: 300px"
            v-if="scope.row.recordUrl"
            :src="scope.row.recordUrl"
            preload="none"
            controls="controls"
          ></audio>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  exportSeatAccountReportList,
  getRecordListByMerchant,
  getUserListOfMerchantByPage,
  exportRecordList
} from "../../api";
import moment from 'moment';

export default {
  name: "",
  data() {
    return {
      userId: this.$store.getters.userId,
      callTalkTimeList: [
        { key: "0-10", value: "10秒内" },
        { key: "10-30", value: "10-30秒" },
        { key: "30-60", value: "30-60秒" },
        { key: "60-60000", value: "1分钟以上" },
      ],
      searchForm: {
        dateRange: [],
        userId: this.$store.getters.userId,
        userIdList: [],
        startTime: null,
        endTime: null,
        callState: null,
        caller: "",
        callee: "",
        time: "",
        talkTimeStart: null,
        talkTimeEnd: null,
      },
      rules: {
        dateRange: [
          {required: true, message: "请选择时间", trigger: "blur"},
        ]
      },
      userList: [],
      list: [],
      showCustomTime: false,
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
    };
  },
  created() {
    this.searchForm.dateRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    this.loadCallRecordList();
    this.loadMerchantUserList();
  },
  methods: {
    async handleExportRecordList() {
      if (!this.searchForm.startTime || !this.searchForm.endTime) {
        this.$message.error("请选择日期后再导出!");
        return;
      }
      this.$confirm(`确定导出坐席报表吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      }).then(() => {
        this.searchForm.merchantId = -1;
        exportRecordList({ ...this.searchForm });
      });
    },
    handleTimeChange(value) {
      this.showCustomTime = value === "0";
    },
    handleDateChange(val) {
      // if (val) {
      //   this.searchForm.startTime = val[0];
      //   this.searchForm.endTime = val[1];
      // } else {
      //   this.searchForm.startTime = "";
      //   this.searchForm.endTime = "";
      // }
    },
    async loadMerchantUserList() {
      let data = await getUserListOfMerchantByPage({
        current: 1,
        size: 200,
        params: {
          userId: this.$store.getters.userId,
        },
      });
      this.userList = data.records;
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadCallRecordList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadCallRecordList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadCallRecordList();
    },
    loadCallRecordList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      if (this.searchForm.time == "0-10") {
        params.params.talkTimeStart = 0;
        params.params.talkTimeEnd = 10;
      } else if (this.searchForm.time == "10-30") {
        params.params.talkTimeStart = 10;
        params.params.talkTimeEnd = 30;
      } else if (this.searchForm.time == "30-60") {
        params.params.talkTimeStart = 30;
        params.params.talkTimeEnd = 60;
      } else if (this.searchForm.time == "60-60000") {
        params.params.talkTimeStart = 60;
        params.params.talkTimeEnd = 60000;
      } else if (this.searchForm.time == "") {
        params.params.talkTimeStart = null;
        params.params.talkTimeEnd = null;
      }
      this.searchForm.startTime = this.searchForm.dateRange[0];
      this.searchForm.endTime = this.searchForm.dateRange[1];
      this.loading = true;
      getRecordListByMerchant(params).then((data) => {
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
